import {credentialsStore} from "~/stores/credentialsStore";
import {customIdFetch} from "~/composables/tools/customIdFetch";
import {useCentralStore} from "~/stores/useCentralStore";
import {useTenantStore} from "~/stores/useTenantStore";

export default defineNuxtRouteMiddleware(async to => {

    const logoutEvent = async () => {
        useCentralStore().logout();
        useTenantStore().logout();
        credentialsStore().setBroker(null)
    }

    // try {
    //     const response = await customIdFetch('/user',{method:'GET'});
    //
    //     if(response.error?.value?.statusCode === 401)
    //     {
    //         await logoutEvent();
    //         return;
    //     }
    //
    //     if(response.data.value.id !== credentialsStore().getBroker && credentialsStore().getBroker !== null)
    //     {
    //         await logoutEvent();
    //     }
    // }catch (e) {
    //
    // }



})